import React from "react"
import * as style from "./Spining.module.css"

const Spining = () => {
  return (
    <div class={style.ldsRing}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Spining
